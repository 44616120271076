:root {
    --primary: var(--blueN) !important;
    --secondary: var(--blueN) !important;
    --green: #22C687 !important;
    --blue: #1BA9EA !important;
    --fadeGreen: #4ED19F;
    --bluwishGray: #4e5983;
    --warning: #FF0000 !important;
    --gray: #707070 !important;
    --orange: #FF9D0C !important;
    --white: #fff !important;
    --black: #000000 !important;
    --blueN: #1BA9EA
}

html, body, #root {
    height: 100%;
}

*, :focus, *:hover {
    outline: none;
}

* {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
}

.App {
    font-family: 'Poppins', poppins;
    height: 100%;
}

.nav-logo {
    font: normal normal 600 16px/16px Poppins;
    letter-spacing: 0px;
    color: var(--primary);
    padding-top: 0px !important;
    cursor: pointer;

}
.c3 {
    margin: 0 8px 0 8px !important;
}
.c3 svg {
    font: 12px Poppins !important;
}
.c3-line {
    stroke-width:2px !important;
    stroke: var(--primary) !important;
    background: linear-gradient(to bottom, #b1d4e0, #145374) !important;
}
.c3-legend-item-tile{
    stroke: var(--primary) !important;
    visibility: hidden !important;
}
.c3-legend-item {
    visibility: hidden !important;
}
.sa-table-btn-mute {
    color: var(--gray)!important;
}
.list-view {
    padding-top: 96px;
    bottom: 0px;
    width: 100%;
    transition: 0.4s;
}
.login-logo-image {
    height: 72px !important;
}
.h-100pe {
    height: 100%;
}

.full-list-view {
    height: 100%;
    padding-top: 133px;
}

.bg-gray {
    background-color: #F8F8F8;
}
.dashboard-widget-card {
    padding-right: 8px;
    height: 120px !important;
    margin-bottom: 20px !important;
    box-shadow: 0px 1px 12px rgba(31, 41, 55, 0.08) !important;
}
.widget-text-value {
    line-height: 16px;
}
.widget-value {
    line-height: 40px;
}
.service-card {
    width: 32%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000000A;
    border: 0.47999998927116394px solid #EDEDED;
    border-radius: 8px 8px 0px 0px;
    opacity: 1;
    position: relative;

}

.p-20 {
    padding: 20px;
}

.service-card-icon {
    width: 40px;
    height: 40px;
    background: #EDF9F4 0% 0% no-repeat padding-box;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.txt-green {
    color: #00AA68;
}

.service-card-heading {
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0px;
    color: #181D33;
}
.header-text{
    font-size: 16px !important;
    font-weight: 600;
    color: var(--black);
}
.service-card-txt {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #181D33;
    opacity: 0.48;
}

.service-card-sub-container {
    background: #EDEDED 0% 0% no-repeat padding-box;
    opacity: 0.5;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;

}

.service-card-sub-container-txt {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #182733C6;
    text-transform: capitalize;
    opacity: 0.8;

}

.service-card-toggle-icon {
    width: 18px;
    height: 16px;
    color: #4A555F;
    cursor: pointer;
}

.service-card-more {
    position: absolute;
    right: 5px;
    rotate: 90deg;
    top: 10px;

}

.heading-txt {
    font: normal normal 600 16px/16px Poppins;
    letter-spacing: 0px;
    color: #111111;
    margin-bottom: 24px;
}

table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.gap-20 {
    gap: 20px;
}

.service-card #serviceDropdown {
    position: absolute;
    right: 0;
}

.module-checkbox {
    height: 20px;
    width: 20px;
}

.module-checkbox-txt {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.module-checkbox-sub-txt {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #707070;
}

.bg-primary {
    background-color: #5895E9 !important;
}

.sa-popup-header {
    margin-bottom: 24px;
    padding-top: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    box-shadow: 0 4px 30px rgb(0 0 0 / 3%);
    background: #fff 0 0 no-repeat padding-box;
    opacity: 1;
    height: 60px;
}

.form-label {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #707070;
}

.view-more {
    font: normal normal normal 12px/18px Poppins;
    text-decoration: underline;
    text-align: right;
    margin-top: 12px;
}

.sa-bread-crumb {
    display: inline-block;
    z-index: 9;
    position: relative;
    color: #868DAA;
    font-size: 12px;
    border: 0.4px solid #EDEDED;
    border-radius: 4px;
    margin-left: -8px;
    pointer-events: all;
    box-shadow: 0px 0px 8px #0000000A;
}

.sa-bc-arrow {
    color: #ddd;
    margin-right: 4px;
    height: 19px;
}

.icon-in-btn {
    width: 16px !important;
    height: 16px !important;
    margin-top: -2px !important;
    margin-right: 2px;
}

.pis-add-activityFields .icon-in-btn {
    margin-left: -3px;
    margin-left: -7px;
}

.sa-bc-plot {
    display: inline-block;
    background: #fff;
    height: 36px;
    padding: 9px 9px 9px 0;
    border-radius: 0 4px 4px 0;
}

.sa-bc-arrow {
    color: #ddd;
    margin-right: 4px;
    height: 19px;
}

.sa-bc-home {
    display: inline-block;
    background: #fff;
    height: 36px;
    padding: 9px 12px;
    border-radius: 4px 0 0 4px;
}

.sa-cursor {
    cursor: pointer;
}

.sa-bc-farm {
    display: inline-block;
    background: #fff;
    height: 36px;
    padding: 9px 9px 9px 0;
}

.map-toggle-btn {
    top: 64px;
    width: 100%;
    padding: 16px 0;
    background-color: transparent;
    transition: 0.4s;
    z-index: 9;
    pointer-events: none;
}

.second-tog {
    border-radius: 0px 4px 4px 0px !important;
    width: 105px;
}

#toggle-list, #toggle-map {
    border: none !important;
}

.toggle-title {
    text-align: left;
    font: normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    font-weight: 400;
    display: inline-block;
  }
  
  .text-dark-gray {
    color: #757575;
  }
  
  .toggle-text-blue {
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #22C674;
  }
  
  .toggle-active-feather {
    color: #22C674;
  }
  
  .toggle-border-bottom {
    border-bottom: 1px solid #F2F2F2;;
  }
  
  .hide-table {
    display: none;
  }

  .form-controls {
    margin: 50px auto;
  }

  .plot-setting-btn-m {
    margin-right: 16px;
  }
  
  @media(max-width: 375px) {
    .plot-setting-btn-m {
      margin-right: 0px;
    }
  }
  
  @media(max-width: 320px) {
    .plot-setting-btn-m {
      margin-bottom: 8px;
    }
  }

  .btn-sa-secondary {
    background: #FFF 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    border: solid 1px #1BA9EA !important;
    opacity: 1;
    height: 44px;
    color: #1BA9EA !important;
    font-size: 14px;
  }

  .px-web-32 {
    padding: 0 32px !important;
  }

  .add-lable-color {
    color: #707070;
  }
  
  .m-lr-5 {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
  }

  .alert-input-box {
    display: flex;
    margin-bottom: 40px;
  }

  .toggle-btn-container {
    width: 24px;
    height: 24px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #F5F5F5;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .form-controls {
    margin: 50px auto;
  }

  .plot-report-tabs {
    display: inline-block;
    width: 20%;
    text-align: center;
    background: #E7E8EA71 0% 0% no-repeat padding-box;
    padding: 20px;
    border: solid 0.5px #fff;
    cursor: pointer;
  }
  
  .plot-report-tabs-title {
    color: #3F4451;
    color: #868DAA;
  }

  .plot-alert-heading {
    font: normal normal 600 16px/25px Poppins;
    color: #FFFFFF;
  }

  .alert-level-box {
    width: 100%;
    background: #E7E8EA71 0% 0% no-repeat padding-box;
    height: 50px;
    padding: 14px 10px 0px 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    margin-top: 8px;
  }

  .toggle-icon-active {
    filter: invert(59%) sepia(93%) saturate(376%) hue-rotate(98deg) brightness(84%) contrast(100%);
  }

  .threshold {
    justify-content: space-between !important;
    align-items: center !important;
  }
  
  .plot-report-tabs-value {
    color: #3F4451;
    font-weight: lighter;
  }

.tog-sa-secondary {
    background: #FFF 0% 0% no-repeat padding-box !important;
    border-radius: 0;
    border: 0.23999999463558197px solid #EDEDED;
    opacity: 1;
    height: 36px;
    color: #2A7CED !important;
    font-size: 14px;
}

.tog-sa-primary {
    background: #2A7CED 0% 0% no-repeat padding-box !important;
    border-radius: 0;
    border: 0.23999999463558197px solid #EDEDED;
    opacity: 1;
    height: 36px;
    color: #fff !important;
    font-size: 14px;
}

.first-tog {
    border-radius: 4px 0px 0px 4px !important;
    width: 105px;
}

.card-sub-contain {
    padding: 6px 20px;
}

.available-module-txt {
    font: normal normal 14px/21px Poppins;
}

.table-action-btn {
    padding: 5px 22px;
    background-color: #EDEDED;
    color: #7A869A;
    border-radius: 12px;
    opacity: 0.54;
    cursor: not-allowed;
    margin-left: 12px;

}

.table-action-btn-active {
    background-color: white;
    color: #2A7CED;
    cursor: pointer;
    border: 1px solid #2A7CED;
    /*font: normal normal 12px/20px Poppins;*/
    font-weight: bold;
    opacity: 1;
}
.rs-picker-toggle-textbox{
    border: 1px solid #0000;
}
.react-datepicker-popper{
    z-index:10000 !important;
}
.w-50px {
    width: 50px !important;
}

.sa-table-item-align {
    align-items: center;
    width: 50px;
}

.rbt-input-multi {
    display: flex !important;
    align-items: center !important;
}

.active-border {
    border: 1px solid var(--primary) !important;
}

.deactive-border {
    border: 1px solid #F7644E !important;
}

.sa-table-container {
    max-height: 412px !important;
}

.display-flex {
    display: flex;
}

.justify-cont-space-bet {
    justify-content: space-between;
}

.justify-cont-space-arr {
    justify-content: space-around;
}

.bottom-border {
    border-bottom: 3px solid var(--primary) !important;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-16 {
    margin-bottom: 16px;
}

.ml-16 {
    margin-left: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.mt-20 {
    margin-top: 20px;
}

@media only screen and (max-width: 800px) {
    .tab-view-col {
        flex-direction: column;
    }

    .date-lable {
        width: 100% !important;
    }

    .mb-8-in-mobile {
        margin-bottom: 8px;
    }

    .form-controls, .alert-container {
        flex-direction: column;
      }
}

.alert-container-box {
    width: 100%;
  }
  
.alert-container {
    display: flex;
  }

.barchart-Wrapper {
    display: table;
    position: relative;
    margin: 20px 0;
    height: 252px;
}

.barChart-Container {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding-left: 15px;
}

.barchart {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 50%;
}

.barchart-Col {
    position: relative;
    vertical-align: bottom;
    display: table-cell;
    height: 100%;
}

.barchart-Col + .barchart-Col {
    border-left: 4px solid transparent;
}

.barchart-Bar {
    position: relative;
    height: 0;
    transition: height 0.5s 2s;
    width: 50%;
    margin: auto;
}

.barchart-Bar:after {
    content: attr(attr-height);
    color: white;
    position: absolute;
    text-align: center;
    width: 100%;
}

.barchart-BarFooter {
    position: absolute;
    text-align: center;
    height: 10%;
    width: 100%;
}

.barchart-BarFooter h3 {
    color: black;
}

.barchart-TimeCol {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.barchart-Time {
    height: 20%;
    vertical-align: middle;
    position: relative;
}

.barchart-Time:after {
    border-bottom: 1px solid black;
    content: "";
    position: absolute;
    width: 50%;
    left: 0;
    top: 0em;
}

.barchart-TimeText {
    position: absolute;
    top: -8px;
    z-index: 1;
    background: white;
    padding-right: 5px;
    color: #4d4d4d;
    font-size: 15px;
    font-family: 'Avenir Medium';
}

.barchart-sub-head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.bar-color {
    background-color: var(--primary);
}

.date-lable {
    width: 20%;
    display: flex;
    align-items: center;
}

.single-card {
    padding: 8px 16px;
    -webkit-box-shadow: 0px 0px 8px #0000000A;
    -moz-box-shadow: 0px 0px 8px #0000000A;
    box-shadow: 0px 0px 8px #0000000A;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 0.23999999463558197px solid #EDEDED;
    background-color: #fff;
}

.view-filter {
    background: #F5F6F7 !important;
    border: 0.75px solid #3F44511F !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    font-size: 14px !important;
    color: #42526E !important;
    width:auto !important;
    height: 35px !important;
}
.location-filter {
    background: #FFFFFF !important;
    border: 0.75px solid #3F44511F !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    color: #42526E !important;
    width:auto !important;
    height: 32px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
}
.show-768 {
    display: block !important;
}
.w-50-per {
    width: 50%;
}


.head-count {
    display: flex;
    justify-content: center;
    font-size: 18px;
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;

}
.nav-active {
    color: var(--primary) !important;
    border-bottom: solid 2px var(--primary);
    margin-bottom: -18px;
}

.user-profile {
    border: 2px solid var(--primary);
}

.center-date {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.plot-report-filter-box-plot {
    width: 100%;
    height: 76px;
    margin-top: 16px;
}
.plot-report-filter{
    float: right !important;
    margin-left:8px;
}
.location-box {
    width: fit-content;
    height: 32px;
    display: flex;
    font-size: 14px;
    padding: 8px 8px 8px 0;
    border-radius: 6px;
    margin-right: 8px;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 4px;
    left: 83.4%;
    right: 4.58%;
    top: calc(50% - 32px/2 - 814.5px);
    background: #FFFFFF;
    /*float:right;*/
}

.date-picker-container {
    width: fit-content;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 8px;
}

.date-picker-text-box {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
    background-color: #F5F6F7;
}

.calendar-icon {
    width: 28px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    margin-right: 4px;
  }

.widget-container {
    /*width: 278px;*/
    padding: 0 8px;
}


.input-range__track--active {
    background: #1BA9EA !important;
    border: 0.6399999856948853px solid #E7E8EA !important;
  }
  
  .input-range__slider {
    background: #1BA9EA !important;
    border: 1px solid #488eef !important;
  }
  
  .input-range__label {
    font-family: 'Poppins', sans-serif !important;
    font-size: 13px !important;
  }
  
  .opacity-30 {
    opacity: 0.3;
  }

  .input-range-label {
    position: inherit;
    font-size: 14px;
    font-weight: bold;
  }
  
  .input-range-label-low {
    right: 50%;
    top: 2px;
  }
  
  .input-range-label-high {
    left: 50%;
    top: 2px;
  }
  
  .input-range__track {
    height: 12px !important;
  }
  
  .input-range__track--background {
    background: #F7F7F7 0% 0% no-repeat padding-box !important;
    border: 0.6399999856948853px solid #E7E8EA !important;
  }
  
  .input-range__slider {
    width: 18px !important;
    height: 18px !important;
    margin-top: -15px !important;
  }
  
  .input-range__label--value {
    top: -35px !important;
    color: #1BA9EA !important;
  }
  
  @media (min-width: 768px) {
    .custom-switch-align {
      margin-right: 40px;
    }
    .filter-left {
      margin-left: -10px;
    }
    .search-right .search {
      display: none;
    }
  }  

  .map-icon {
    height: 24px;
    width: 24px;
    margin-top: -3px;
    margin-left: 3%;
  }
  
  .half-list-view {
    padding-top: 0;
  }
   
  #searchbar {
    padding-left: 1.5%;
  }
  
  .single-card-content {
      padding: 10px;
      border-bottom: solid 1px #eee;
      background-color: #FDFDFD;
      border-radius: 8px;
    }
    
    .card-header-text {
        height: 60px;
        background-color:rgba(0, 0, 0, 0.062);
        border-radius: 8px 8px 0 0;
        padding: 4% 0 0 4%;
        text-wrap: nowrap;
      
      }
      .dashboard-widget-card-2nd{
        padding-right: 8px;
        padding-left: 8px;
        padding-top:8px;
        height: 57px !important;
        margin-bottom: 20px !important;
        margin-top: 20px;
        box-shadow: 0px 1px 12px rgba(31, 41, 55, 0.08) !important;
      }
      .widget-text-value-2nd {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        line-height: 8px;
        width: 100%;
        text-align: left;
        /* text-wrap: nowrap; */
      }
      .dashboard-widget-card-text-layout-2nd {
        padding-left: 3px;
        width: 100%;
      }
      .widget-value-2nd {
        font-weight: 600;
        font-size: 15px;
        line-height: 48px;
        text-align: left;
      
      }