*, :focus, *:hover {
  outline: none;
}

* {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}

.sa-popup-bg {
  position: fixed;
  display: table;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.sa-popup {
  display: table-cell;
  vertical-align: middle;
}

.sa-modal-box-style {
  background: var(--white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
  padding-bottom: 16px;
  max-width: 841px;
  max-height: calc(100vh - 100px);
  margin: 0px auto;
  overflow: auto;
}

.sa-modal-box-style-over-flow {
  background: var(--white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  opacity: 1;
  padding-bottom: 16px;
  max-width: 841px;
  max-height: calc(100vh - 100px);
  margin: 0px auto;
  overflow: visible;
}

.sa-modal-header {
  font-size: 26px;
  font-weight: 600;
  color: #181D33;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  overflow: hidden
}

@media(max-width: 767px) {
  .sa-modal-box-style {
    width: calc(100% - 24px);
  }
  .sa-filter {
    margin-left: 0px !important;
    margin-top: 8px;
    margin-right: 8px;
  }
  .single-card-content-notification {
    margin-right: -34px !important;
  }
  .sa-table-width {
    width: 600px !important;
  }
  .sa-table-btn-secondary {
    width: 36px;
  }
  .sa-modal-header {
    font-size: 19px;
  }
  .sa-table-btn-secondary-search {
    width: 240px;
  }
  .form-control {
    width: 100% !important;
  }
  .sa-modal-bg-inner {
    display: block;
  }
}

.sa-model-heading {
  font: normal normal normal 20px/30px Poppins;
  font-weight: 600;
  color: #1D283D;
  margin-top: 16px;
  margin-left: 16px;
}

.sa-popup-content {
  width: calc(100% - 16px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.form-control {
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  height: 44px !important;
}

.form-control:focus {
  border: solid 1px #2196F3 !important;
  box-shadow: none !important;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -35px;
  position: relative;
  margin-right: 8px;
  color: #868DAA !important;
}

.toggle-password {
  width: 20px;
  transition: 0.4s;
}

.warning-input-msg {
  color: var(--warning) !important;
  font-size: 10px;
  margin-left: 15px;
  margin-top: 5px;
}

.form-group {
  margin-bottom: 0px;
}

.form-control {
  background: var(--white) !important;
  border: 0.75px solid #3F44511F !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  font-size: 14px !important;
  color: #868DAA !important;
}

.login-warning-msg {
  text-align: center;
  color: var(--warning);
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 4px;
}

.sa-popup-btn {
  text-align: right !important;
  margin-right: 16px;
}

.sa-popup-secondary-btn-style {
  top: 518px;
  left: 920px;
  width: 160px;
  height: 40px;
  background: var(--secondary) 0 0 no-repeat padding-box;
  color: var(--white);
  border-radius: 4px;
  opacity: 1;
  text-transform: uppercase;
  border: none;
}

.sa-popup-primary-btn-style {
  top: 518px;
  left: 920px;
  width: 160px;
  height: 40px;
  background: var(--primary) 0 0 no-repeat padding-box;
  color: var(--white);
  border-radius: 4px;
  opacity: 1;
  text-transform: uppercase;
  border: none;
}

.sa-popup-cancel-btn-style {
  background: var(--white);
  color: var(--gray);
}

@media(max-width: 320px) {
  .sa-popup-btn {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    margin-left: 16px !important;
  }
  .single-card-content-notification {
    margin-right: -38px !important;
  }
  .sa-popup-secondary-btn-style {
    width: 100%;
  }
}

.hide {
  visibility: hidden;
}

.sa-popup-header {
  margin-bottom: 24px;
  padding-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: 0px 4px 30px #00000008;
  background: var(--white) 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 60px;
}

.sa-popup-close-icon {
  float: right;
  text-align: center;
  color: rgb(119, 110, 110);
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-right: 16px;
}

.sa-popup-delete-btn-style {
  background: var(--warning) 0% 0% no-repeat padding-box;
  color: var(--white);
}

.p-l-16 {
  padding-left: 16px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.warning-heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.text-red {
  color: var(--warning) !important;
}

.warning-text {
  margin-bottom: 8px;
  font-size: 16px;
  color: #757575;
}

.btn-sa-danger {
  background: var(--warning) 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  color: var(--white) !important;
  font-size: 14px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.deleteReason {
  margin-left: -8px;
  margin-top: -20px;
}

.dropdown-item {
  font-size: 13px;
  padding: 8px 8px !important;
}

.btn {
  text-transform: uppercase !important;
}

.w-120p {
  width: 120px;
}

.loader-model-bg-hidden {
  position: fixed;
  display: table;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -999;
}

.loader-model-bg-visible {
  position: fixed;
  display: table;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
}

.sa-modal-bg-inner {
  display: table-cell;
  vertical-align: middle;
  padding: 8px;
}

.sa-modal-bg-inner-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader-model {
  padding: 45px;
  max-width: 674px;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1300px !important;
  }
}

@media (max-width: 1200px) {
  .corporate-dropDown {
    background: none;
    margin-right: none;
    height: none !important;
    border: none !important;
    padding: 0 4px !important;
  }
  .corporate-name {
    display: none;
  }
}

.loader-container {
  position: relative;
}

.sa-modal-bg {
  position: fixed;
  display: table;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.sa-modal-bg-notification {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.sa-table-head-dashboard {
  position: sticky;
  top: 0;
  font-size: 14px;
  color: #00000080;
  padding: 8px !important;
  background-color: var(--white);
}

.dashboard-widget-card {
  height: 152px;
  padding: 28px;
  background-color: white;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  flex-direction: column;
}

.dashboard-yield-card {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.dashboard-widget-card-layout {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.dashboard-widget-card-icon-container {
  width: 64px;
  height: 64px;
  background: #F8F8F8;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-widget-card-text-layout {
  padding-left: 24px;
}

.font-size-18 {
  font-size: 18px;
}

.widget-text-value {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 8px;
}

.widget-value {
  font-weight: 600;
  font-size: 20px;
  line-height: 48px;
}

.widget-sub-value-text {
  color: #22C687;
  font-size: 14px;
  line-height: 20px;
  padding: 0 12px 0px 5px;
}

.widget-sub-text-value {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.loader {
  z-index: 99999 !important;
}

.sa-modal-bg-inner-centered {
  width: fit-content;
  display: table-cell;
  vertical-align: middle;
}

.login-background {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  height: 100%;
  width: 100%;
  display: table;
  padding-left: 16px;
  padding-right: 16px;
}

.login-inner {
  display: table-cell;
  vertical-align: middle;
}

.login-box {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED;
  border-radius: 8px;
  opacity: 1;
  padding: 40px;
  max-width: 512px;
  margin: 0px auto;
}

.login-logo {
  text-align: center;
  margin-bottom: 22px;
}

.dunya-logo-image {
  height: 144px;
  margin-bottom: -20px;
}

.login-logo-image {
  height: 30px;
}

.login-text-btn {
  color: #4E5983;
  opacity: 0.48;
  font-size: 14px;
}

.m-b-9 {
  margin-bottom: 9px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-sa-primary {
  background: var(--primary) 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  opacity: 1;
  height: 44px;
  color: var(--white) !important;
  font-size: 14px;
}

@media (max-width: 320px) {
  .w-100 {
    width: 100px !important;
  }

  .sa-modal-box-mobile {
    max-width: 305px !important;
  }
}

.m-b-14 {
  margin-bottom: 14px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.active-input {
  border: solid 1px #2196F3 !important;
}

.sa-table-heading {
  display: flex;
  justify-content: center;
  padding: 10px;
  font: Regular bold 14px Poppins !important;
}

@media(max-width: 425px) {
  .sa-table-heading {
    display: none;
  }

  .side-menu-btn {
    margin-right: 27px;
  }


  .sa-table-heading-mobile {
    display: flex;
  }
  .sa-table-icon-flex {
    justify-content: flex-end;
  }
  .sa-table-m-r-0 {
    margin-right: 0px !important;
  }
  .sa-table-h-float-l {
    float: right !important;
    margin-left: 8px;
  }
  .sa-table-col {
    flex-direction: column-reverse !important;
    justify-content: flex-end;
  }
}

.notification-details {
  top: 138px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 352px;
  margin-bottom: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  opacity: 1;
  padding-bottom: 6px;
}

.notification-details:hover {
  background-color: #F5F5F5 !important;
}

.notification-details:hover > .image-container-toggle {
  background-color: #fff;
}

.notification-title {
  top: 160px;
  text-align: left;
  letter-spacing: 0;
  color: #707070;
  font-size: 12px;
  padding: 0px 15px 0px;
}

.expand-notification {
  margin-left: 56px;
}

.notification-description {
  top: 164px;
  letter-spacing: 0;
  color: #030303;
  opacity: 0.4;
  font-size: 12px;
  padding: 0px 15px 0px;
}

.notification-since-time {
  font: normal normal 300 10px Poppins;
  letter-spacing: 0px;
  color: #979797;
  opacity: 1;
  padding: 0px 15px 0px;
  vertical-align: text-top;
}

.limit-chara {
  display: block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-ellipsis {
  border-radius: 6px;
  margin-right: 3px;
  outline: none;
  border: none;
  background-color: #ffffff;
  text-align: center;
  font: normal normal normal 12px Poppins;
  letter-spacing: 0px;
  color: #CCCCCC;
  opacity: 1;
  float: right;
}

.button-ellipsis:focus {
  outline: none;
  border: none;
}

.expand-button-ellipsis {
  margin-top: -22px;
  padding-bottom: 0;
}

.sa-table-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sa-table-btn-secondary {
  background: var(--primary) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 32px;
  width: 122px;
  color: var(--white);
  font-size: 14px;
}

.sa-table-btn-secondary-small {
  background: var(--primary) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 32px;
  width: 32px;
  color: var(--white);
  font-size: 14px;
}

.sa-table-btn-secondary-popup {
  margin-top: auto;
  margin-bottom: auto;
  background: var(--primary) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 28px;
  width: 28px;
  color: var(--white);
  font-size: 14px;

  .sa-table-icon-size {
    height: 16px;
    width: 16px;
  }
}

.sa-table-btn-mute {
  background: var(--white) 0% 0% no-repeat padding-box;
  color: #aaa !important;
  border: solid 0.75px #E7E8EA !important;
  cursor: pointer;
}

.sa-search-align {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 32px;
  border-radius: 4px;
}

.sa-table-float-left {
  float: left;
}

.sa-table-search {
  border: none;
  padding-left: 10px;
  width: 208px;
}

.font-14 {
  font-size: 14px;
}

.sa-search-icon {
  margin-right: 10px;
}

.sa-filter {
  min-width: 94px !important;
  height: 32px !important;
  padding: 5px !important;
  background: #091E420A !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: #868DAA !important;
  cursor: pointer !important;
  margin-left: 8px;
  margin-right: 8px;
}

@media(min-width: 428px) and (max-width: 492px) {
  .sa-filter-report {
    margin-top: 0px !important;
  }
}

@media(min-width: 560px) and (max-width: 575px) {
  .sa-filter {
    margin-top: 0px !important;
    margin-left: 8px !important;
  }
}

.single-card-content-filter {
  overflow-x: hidden;
  min-width: 304px !important;
  min-height: 150px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000A;
  border: 0.23999999463558197px solid #EDEDED !important;
  opacity: 1;
}

@media(max-width: 820px) {
  .sa-table-daterange-hide {
    display: none;
  }
}

.sa-table-icon-flex {
  display: flex;
  flex-direction: row;
}

@media(min-width: 426px) {
  .sa-table-heading-mobile {
    display: none;
  }
}

.sa-table-float-l-head {
  margin-right: auto;
}

.sa-table-float-right {
  float: right;
  margin-left: 8px;
}

.sa-table-icon-size {
  height: 20px;
  width: 20px;
}

.sa-table-container {
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 0.75px solid #3F44511F;
  border-radius: 4px;
  margin: 16px 0px 16px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.sa-table-head-sticky {
  position: sticky;
  top: 0;
  background-color: var(--white);
}

.sa-table-item-align {
  align-items: center;
  width: 50px;
}

.sa-table-data {
  color: #000 !important;
}

.sa-table-action-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sa-table-actions {
  display: inline-block;
  color: #aaa;
  cursor: pointer;
}

.sa-action-m {
  margin-right: 8px;
}

.sa-table-pagination {
  color: rgba(0, 0, 0, 0.5) !important;
  font: normal 12px Poppins !important;
}

.warning-input {
  border: solid 1px var(--warning) !important;
}

.login-text-btn a, .login-text-btn a:hover {
  color: var(--bluwishGray);
  text-decoration: underline;
}

.sm-navbar {
  position: fixed !important;
  width: 100%;
  height: 64px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.23999999463558197px solid #EDEDED;
  -webkit-box-shadow: 0px 0px 8px #0000000A;
  -moz-box-shadow: 0px 0px 8px #0000000A;
  box-shadow: 0px 0px 8px #0000000A;
  z-index: 99;
}

@media screen and (max-width: 992px) {
  .mobile-hide {
    display: none;
  }
  .sa-table-icon {
    display: none;
  }
  .sa-table-btn-secondary {
    width: 32px !important;
  }
  .sa-navbar-container {
    margin-top: -10px;
  }
}

.icon-in-nav {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.icon-in-module {
  width: 32px;
  height: 32px;
  /* margin-right: -1px; */
}

.icon-in-weather-module {
  width: 28px;
  height: 26px;
  margin-right: -1px;
  margin-top: 5px;
}

.icon-in-nav-active {
  width: 22px;
  height: 22px;
  display: none;
}

.nav-active-mv .icon-in-nav {
  display: none;
}

.nav-active-mv .icon-in-nav-active {
  display: unset;
}

.navbar-nav a {
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0;
  color: #707070;
  text-transform: uppercase;
  opacity: 0.7;
  margin-right: 20px;
}

.navbar-nav-connect a {
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0;
  color: #707070;
  text-transform: uppercase;
  opacity: 0.7;
  margin-right: 20px;
  font-size: 14px;
}

.navbar-expand-md .navbar-nav {
  height: 21px;
}

@media(min-width: 768px) and (max-width: 990px) {
  .nav-active {
    margin-bottom: -13px !important;
  }
}

.nav-active {
  color: var(--primary) !important;
  border-bottom: solid 2px var(--primary);
  margin-bottom: -18px;
}

.nav-active-mv {
  color: var(--primary) !important;
}

.nav-active-settings .settings-icon-b {
  color: var(--primary) !important;
}


.collapse-navbar a {
  font-size: 14px;
}

.collapse-navbar a:hover, .settings-icon-b:hover {
  color: var(--primary) !important;
}

.profile-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

}

#dropdown-custom-components, #dropdown-custom-components-bell, #dropdown-custom-components-profile {
  background-color: #fff;
  color: #000000;
  border: none;
  font-size: 12px;
}

#dropdown-custom-components:focus, #dropdown-custom-components-bell:focus, #dropdown-custom-components-profile:focus {
  box-shadow: none;
}

.user-profile-image {
  height: 36px;
}

.user-profile-inner {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #FFFFFF;
}

.user-profile {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 9px;
  border: 2px solid var(--primary);
}

.circle-in {
  fill: #f81d00;
  size: 30px;
  height: 50%;
  width: 50%;

}

.icon-in-notification {
  width: 26px;
  height: 26px;
  color: var(--primary);
}

.icon-red {
  color: #ff8066 !important;
}

.image-container-toggle {
  text-align: center;
  height: 48px;
  width: 48px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #F5F5F5;
  border-radius: 4px;
  padding: 11px;
  display: inline-block;
}

.notification-dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 100%;
  right: 0;
}

.bell-icon-b {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  color: #AEB3C6 !important;
}

.bell-icon-b:hover {
  color: var(--primary) !important;
}

.bell-icon-active {
  color: var(--primary) !important;
}

.setting-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1 !important;
  text-align: center;
  margin: 0 auto;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #AEB3C6 !important;
}

.setting-icon-active {
  color: var(--primary) !important;
  border-radius: 10px;
}

.dropdown-toggle::after {
  display: none !important;
}

@media screen and (max-width: 992px) {
  #profileDropdown {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .side-menu-btn {
    display: block !important;
  }
}


.side-menu-btn {
  display: none;
  cursor: pointer;
  margin-right: 16px;
  color: #868DAA;
}


.navbar-nav a:hover, 
.navbar-nav-connect a:hover {
  text-decoration: none;
}

.navbar-nav a:focus,
.navbar-nav-connect a:focus {
  text-decoration: none;
}

.navbar-nav a:checked,
.navbar-nav-connect a:checked {
  text-decoration: none;
}

.dropdown-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #ddd;
  background-color: #eee;
  text-align: center;
  font-size: 13px;
  margin-left: 8px;
}

.nav-setting {
  margin: 1px -12px 0px 0px;
}

.sa-cursor {
  cursor: pointer;
}

.navbar-nav .active {
  color: var(--primary) !important;
  border-bottom: solid 2px var(--primary);
  margin-bottom: -18px;
}

@media (min-width: 769px) and (max-width: 992px) {
  .setting-element-space {
    margin-left: auto !important;
    margin-right: -14px !important;
  }
}

.side-nav-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 99;
  padding: 16px;
  padding-left: 33px;
}

.user-menu-profile {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 9px;
  margin-top: 24px;
  border: 2px solid var(--primary);
}

.dropdown-menu {
  padding: 0 !important;
  min-width: auto !important;
}

.side-menu-nav {
  color: #707070;
  font-size: 16px;
  padding: 15px 0;
  cursor: pointer;
  text-decoration: none;
}

.side-menu-nav:hover {
  text-decoration: none;
  color: var(--primary) !important;
}

.side-menu-nav-icon {
  color: #707070;
  height: 19px;
  width: 29px;
}

.side-menu-nav-icon-active {
  color: var(--primary) !important;
}

.side-menu-nav-log-icon {
  font-size: 16px !important;
  height: 19px;
  width: 29px;
}

.side-menu-logout {
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: var(--warning);
  margin: -1px -16px;
  width: 100%;
  height: 100%;
  color: #fff;
  margin-left: -33px;
  padding-left: 36px;
}

.side-menu-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}


.side-menu-name {
  text-transform: uppercase;
  color: #707070;
  margin-bottom: 26px !important;
  font-weight: 600;
  margin-top: 8px;
}

.side-menu-close-btn {
  float: right;
  cursor: pointer;
}

.sa-notification-sticky {
  position: sticky;
  top: 0px !important;
  background-color: #ffffff;
  z-index: 1000;
  padding-top: 10px;
  margin-right: -12px !important;
}

.notification-calc-height {
  max-height: calc(100vh - 250px);
}

.single-card-content-notification {
  position: absolute;
  border: solid 1px #eee !important;
  background-color: #FDFDFD !important;
  width: 384px;
  overflow-y: auto;
  overflow-x: hidden;
  float: left !important;
  margin-right: -160px !important;
  margin-top: 17px !important;
}

@media (max-width: 575px) {
  .single-card-content-notification {
    width: 400px;
    height: 100vh;
    overflow-y: auto;
  }
  .notification-title {
    font-size: 12px;
  }
  .notification-description {
    font-size: 10px;
  }
  .notification-text {
    font: bold 15px/16px Poppins;
  }
  .update-text {
    font: bold 15px/16px Poppins;
  }
  .update-text1 {
    font: bold 15px/16px Poppins;
  }
}

@media (max-width: 480px) {
  .single-card-content-notification {
    width: 408px;
    height: 515px;
    overflow-y: auto;
  }
  .sa-table-search {
    width: 90% !important;
  }
  .sa-search-align {
    width: 70% !important;
  }
  .notification-details {
    width: 381px;
  }
  .notification-title {
    font-size: 12px;
  }
  .notification-description {
    font-size: 10px;
  }
  .notification-text {
    font: bold 15px/16px Poppins;
  }
  .update-text {
    font: bold 15px/16px Poppins;
  }
  .update-text1 {
    font: bold 15px/16px Poppins;
  }
}

@media (max-width: 420px) {
  .single-card-content-notification {
    width: 350px;
    overflow-y: auto;
  }
  .notification-details {
    width: 327px;
  }

  .notification-title {
    font-size: 12px;
  }
  .notification-description {
    font-size: 10px;
  }
  .notification-text {
    font: bold 15px/16px Poppins;
  }
  .update-text {
    font: bold 15px/16px Poppins;
  }
  .update-text1 {
    font: bold 15px/16px Poppins;
  }
}

@media (max-width: 375px) {
  .single-card-content-notification {
    width: 358px;
    overflow-y: auto;
  }
  .notification-details {
    width: 332px;
  }
}

@media (max-width: 320px) {
  .single-card-content-notification {
    width: 317px;
    overflow-y: auto;
    margin-right: 38px !important;
  }
  .notification-details {
    width: 292px;
  }
}

@media(max-width: 425px) {
  .single-card-content-notification {
    margin-right: -31px !important;
  }
}

@media(min-width: 768px) {
  .single-card-content-notification {
    margin-top: 13px !important;
  }
}

.row {
  margin-right: -8px !important;
  margin-left: -8px !important;
}


.empty-results {
  padding: 64px 0;
  text-align: center;
  color: #999;
  width: 100%;
}

.empty-results-text {
  color: #3F4451;
  margin-top: 12px;
  font-size: 16px;
}

.error-results-text {
  color: #3F4451;
  margin-top: 12px;
  font-size: 16px;
  text-align: center;
}

.notification-text {
  height: 23px;
  font: bold 16px Poppins;
  letter-spacing: 0px;
  color: #3C3C3C;
  opacity: 1;
}

.sub-heading-text {
  margin-top: 8px;
  height: 20px;
  font: bold 12px Poppins;
  letter-spacing: 0px;
  color: #3C3C3C;
  opacity: 1;
}

.setting-element-space {
  margin-right: -28px !important;
}

.navbar-top {
  margin-top: 5px !important;
}

.setting-text {
  height: 40px;
  text-align: left;
  font: normal normal normal 16px Poppins;
  letter-spacing: 0;
  color: #1d283d;
  margin-top: -8px;
  padding: 16px 0 16px 24px;
  cursor: default;
}

.setting-details {
  width: 100%;
  opacity: 1;
  padding: 8px;
}

.sa-icon-setting {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 16px;
  border-radius: 4px;
  font-size: 26px;
  width: 36px;
  height: 36px;
  background-color: var(--primary) !important;
  color: #fff;
  text-align: center;
  justify-content: center;
  vertical-align: initial;
  float: left;
}

.setting-description, .setting-title {
  letter-spacing: 0;
  color: #707070;
  opacity: 1;
  margin-left: 66px;
}

.single-card-content-setting {
  overflow-y: auto;
  overflow-x: hidden;
  float: left !important;
  margin-right: -192px !important;
  margin-top: 12px !important;
  padding-bottom: 16px !important;
  width: 514px;
  min-height: 150px;
  border: 0.2399999946px solid #ededed !important;
}

.single-card-content-corporate {
  overflow-y: auto;
  overflow-x: hidden;
  float: left !important;
  margin-right: -192px !important;
  padding-bottom: 16px !important;
  width: 514px;
  min-height: 150px;
  border: 0.2399999946px solid #ededed !important;
}

.setting-hover-effect :hover {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.translate-tick {
  float: right;
  margin-right: 8px;
  color: var(--primary) !important;
}

.corporate-dropDown {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.25px solid #B1B1B1;
  border-radius: 6px;
  /*width: 113px;*/
  height: 39px;
  font: normal normal normal 12px/12px Poppins;
  letter-spacing: 0px;
  color: #181D33;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.color-green {
  color: var(--primary) !important;
}

.w-100-all {
  width: 100% !important;
}